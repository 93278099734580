<template>
    <div class="analysisPage">
        <div class="analysis_page_content">
            <div class="record_bar" @click="jumpRecord">
                <div class="record_icon">
                    <van-icon color="#fff" size="24" name="records" />
                </div>
                <div class="record_text">
                    做题记录
                </div>
            </div>
            <van-nav-bar
                    title="分析"
                    left-arrow
                    @click-left="$router.go(-1)"
            />
            <div class="analysis_title">
                {{studentScoreData.name}}
            </div>
            <div class="analysis_header">
                <div class="header_title">总览</div>
                <div class="score_process">
                    <div class="process_title">得分率</div>
                    <div class="process_box">
                        <div class="process_bg"></div>
                        <div class="process_line" :style="{width:`${studentScoreData.total_score_rate}% `}"></div>
                    </div>
                    <div class="score_item">
                        <div class="score_number">
                            <div class="user_score">{{studentScoreData.total_score_rate}}</div>
                            <div class="total_score">/100</div>
                        </div>
                    </div>
                </div>
                <div class="score_info">
                    <div class="score_item" v-for="(difficulty,index) in difficulty_list" :key="index">
                        <div class="score_number">
                            <div class="user_score">{{difficulty.student_total_score}}</div>
                            <div class="total_score">/{{difficulty.total_score}}</div>
                        </div>
                        <div class="score_text">{{difficulty.name}}得分</div>
                    </div>
                    <!-- <div class="score_item">
                        <div class="score_number">
                            <div class="user_score">{{scoreNormal.value}}</div>
                            <div class="total_score">/{{scoreNormal.total}}</div>
                        </div>
                        <div class="score_text">中难度得分</div>
                    </div>
                    <div class="score_item">
                        <div class="score_number">
                            <div class="user_score">{{scoreHard.value}}</div>
                            <div class="total_score">/{{scoreHard.total}}</div>
                        </div>
                        <div class="score_text">高难度得分</div>
                    </div> -->
                </div>

            </div>
            <div class="analysis_box">
                <div class="analysis_item" v-show="study_proposal.length > 0 || sure_up_score.count_min_up_score > 0">
                    <div class="analysis_block" v-if="study_proposal.length > 0">
                        <div class="item_title">学习建议</div>
                        <div class="item_content">
                            <div class="content_title">
                                你以下问题比较薄弱：
                            </div>
                            <div class="weakness_box">
                                <div class="weakness_item" v-for="(item,index) in study_proposal" :key="index">
                                    {{item.name}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="analysis_block" v-show="sure_up_score.count_min_up_score > 0">
                        <div class="item_content">
                            <div class="content_title">
                                你的可提分空间：
<!--                                <span class="mark_remark" v-if="sure_up_score.count_min_up_score == sure_up_score.count_max_up_score">-->
<!--                                    （可提分{{sure_up_score.count_min_up_score}}分）-->
<!--                                </span>-->
<!--                                <span class="mark_remark" v-else-if="sure_up_score.count_min_up_score < sure_up_score.count_max_up_score">-->
                                <span class="mark_remark" >
<!--                                    （可提分{{sure_up_score.count_min_up_score}}~{{sure_up_score.count_max_up_score}}分）-->
                                    （可提分{{sure_up_score.count_min_up_score === sure_up_score.count_max_up_score
                                    ? sure_up_score.count_min_up_score : `${sure_up_score.count_min_up_score}~${sure_up_score.count_max_up_score}`
                                    }}分）
                                </span>
                            </div>
                            <div class="weakness_box">
                                <div class="weakness_item" v-for="(item,index) in sure_up_score.list" :key="index">
                                    {{item.name}}
                                    <span class="mark_remark" v-if="item.min_up_score == item.max_up_score">（可提分{{item.min_up_score}}分）</span>
                                    <span class="mark_remark" v-else-if="item.min_up_score < item.max_up_score">（可提分{{item.min_up_score}}~{{item.max_up_score}}分）</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="analysis_item">
                    <div class="analysis_block">
                        <div class="item_title">知识点掌握情况</div>
                        <div class="item_content">
                            <van-row class="kpAnalysisTitle">
                                <van-col :span="8">
                                    <span>知识点</span>
                                </van-col>
                                <van-col :span="12"></van-col>
                                <van-col :span="4"><span>成就度</span></van-col>
                            </van-row>
                            <van-row v-for="kp in kp_tag_analysis" :key="kp.id" class="kpAnalysis">
                                <van-col :span="8" class="kpName">
                                    <span>{{kp.name}}</span>
                                </van-col>
                                <van-col :span="8" class="kpSlider">
                                    <van-slider :disabled="true" :active-color="kpSliderColorInName(kp.total_score_rate,'color')" button-size="0px" bar-height="10" v-model="kp.total_score_rate" />
<!--                                    <van-slider :active-color="'#F9B723'" button-size="0px" bar-height="10" v-model="kp.total_score_rate"/>-->
                                </van-col>
                                <van-col :span="4"  class="kpName">
                                    {{kp.total_score_rate}}%
                                </van-col>
                                <van-col :span="4"  class="kpName">
                                    {{kpSliderColorInName(kp.total_score_rate,'name')}}
                                </van-col>
                            </van-row>
                            <!--<div class="analysis_line" v-for="(item,index) in question_type_analysis" :key="index">
                                <div class="line_item"><span class="line_label">{{item.name}}：</span>{{item.student_total_score}} /
                                    {{item.total_score}}
                                </div>
                                <div class="line_item"><span class="line_label">得分率：</span>{{item.total_score_rate}}%
                                </div>
                                <div class="line_item" v-html="calcRateText(item.level,item.level_name)">
                                </div>
                            </div>-->
                        </div>
                    </div>
                </div>

                <div class="analysis_item">
                    <div class="analysis_block">
                        <div class="item_title">题型分析</div>
                        <div class="item_content">
                            <table class="questionTypeTable">
                                <tr class="tableTitle">
                                    <th></th>
                                    <th>满分</th>
                                    <th>得分</th>
                                    <th>得分率</th>
                                    <th>水平</th>
                                </tr>
                                <tr class="tableContent" v-for="(item,index) in question_type_analysis" :key="index">
                                    <th>{{item.name}}</th>
                                    <th>{{item.total_score}}</th>
                                    <th>{{item.student_total_score}}</th>
                                    <th>{{item.total_score_rate}}%</th>
                                    <th>
                                        <div class="line_item" v-html="calcRateText(item.level,item.level_name)"></div>
                                    </th>
                                </tr>
                            </table>
                            <div class="canvas_item" ref="radar"></div>

                            <!--<div class="analysis_line" v-for="(item,index) in question_type_analysis" :key="index">
                                <div class="line_item"><span class="line_label">{{item.name}}：</span>{{item.student_total_score}} /
                                    {{item.total_score}}
                                </div>
                                <div class="line_item"><span class="line_label">得分率：</span>{{item.total_score_rate}}%
                                </div>
                                <div class="line_item" v-html="calcRateText(item.level,item.level_name)">
                                </div>
                            </div>-->
                        </div>
                    </div>
                </div>
                <div class="analysis_item" v-if="error_cause_analysis && error_cause_analysis.length>0">
                    <div class="analysis_block">
                        <div class="item_title">错因分析</div>
                        <div class="item_content">
                            <div class="canvas_box" v-for="(item,index) in error_cause_analysis" :key="index">
                                <div class="canvas_item" :ref="`reason_${index}`">{{`reason_${index}`}}</div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    let radar;
    import analysisApi from '../api/class/examinationAnalysis'

    export default {
        name: "Analysis",
        data() {
            return {
                postParams:{
                    testId: this.$route.query.testId,
                },
                studentScoreData : {
                    name : undefined,
                    username : undefined,
                    real_name : undefined,
                    student_total_score : 0,
                    total_score : 0,
                    total_score_rate: 0,
                    test_id : undefined,
                    examination_id: undefined,

                },
                //难度得分
                difficulty_list : [],

                //学习建议
                study_proposal : [],
                //可提分
                sure_up_score : {
                    count_min_up_score : 0,
                    count_max_up_score : 0,
                    list : [],
                },

                //题型分析
                question_type_analysis : [],

                //知识点分析
                kp_tag_analysis : [],

                //错因分析
                error_cause_analysis : [],

                //雷达图分析对象数据
                radarOption: null,

                //显示雷达的起始数量
                radarStartNum:2,

                //要分析的数据
                analysisData: [],

                //错因分析数据
                wrongReasonData: [],

                //测试标题
                pageTitle:'2021.06.03 测试',

                scoreData:{
                    statistic:{
                        value:70,
                        total:100,
                    },
                    easy:{
                        value:80,
                        total:100,
                    },
                    normal:{
                        value:80,
                        total:100,
                    },
                    hard:{
                        value:80,
                        total:100,
                    },
                }
            }
        },
        computed:{
            //总计
        },
        activated() {
            this.chartInit()
            this.getAnalysis();

        },
        methods: {
            //跳转做题记录
            jumpRecord(){
                this.$router.push({
                    name: 'titleList',
                    query: {testId: this.studentScoreData.test_id, correctable: ''}
                })
            },
            calcRateText(level,level_name) {
                let color = '', text = '';
                if (level == 1) {
                    color = 'rgba(3, 195, 102, 1)'
                    text = level_name
                } else if (level == 2) {
                    color = 'rgba(1, 159, 232, 1)';
                    text = level_name;
                } else if (level == 3) {
                    color = 'rgba(106, 208, 255, 1)';
                    text = level_name;
                } else if (level == 4) {
                    color = 'rgba(255, 101, 92, 1)';
                    text = level_name;
                }

                return `<span class="rateText" style="color:${color}">${level_name}</span>`;

            },
            //获取分析数据
            getAnalysis() {
                //异步获取
                analysisApi.getStudentAnalysis(this.postParams).then(res => {
                    if(res.success){
                        this.studentScoreData.name = res.data.name;
                        this.studentScoreData.username = res.data.username;
                        this.studentScoreData.real_name = res.data.real_name;
                        this.studentScoreData.total_score = res.data.total_score;
                        this.studentScoreData.total_score_rate = res.data.total_score_rate;
                        this.studentScoreData.student_total_score = res.data.student_total_score;
                        this.studentScoreData.examination_id = res.data.examination_id;
                        this.studentScoreData.test_id = res.data.test_id;
                        // this.$echarts.init(this.$refs.radar)
                        // if(res.data.difficulty_list && res.data.difficulty_list.length > 0){
                        this.difficulty_list = res.data.difficulty_list;
                        // }

                        this.error_cause_analysis = res.data.error_cause_analysis;
                        if(res.data.error_cause_analysis && res.data.error_cause_analysis.length > 0){
                            this.reasonDraw();
                        }

                        this.question_type_analysis = res.data.question_type_analysis;
                        if(res.data.question_type_analysis && res.data.question_type_analysis.length > 0){
                            if (this.question_type_analysis.length > this.radarStartNum) {
                                this.radarDraw();
                            } else {
                                this.analysisBarDraw();
                            }
                        }

                        if (res.data.kp_analysis && res.data.kp_analysis.length > 0){
                            this.kp_tag_analysis = res.data.kp_analysis
                        }
                        console.log(this.kp_tag_analysis )

                        // if(res.data.study_proposal && res.data.study_proposal.length > 0){
                        this.study_proposal = res.data.study_proposal;
                        // }

                        // if(res.data.sure_up_score.list.length > 0){
                        this.sure_up_score.count_min_up_score = res.data.sure_up_score.count_min_up_score;
                        this.sure_up_score.count_max_up_score = res.data.sure_up_score.count_max_up_score;
                        this.sure_up_score.list = res.data.sure_up_score.list;
                        // }
                    }else{
                        console.log('错误')
                    }
                })
                // ...
                //雷达图相关
                //此处获取题目分析相关的数据字段,传递这个数据相应雷达图、分析数据都会自动变更
                let resData = [
                    {
                        name: '填空题',
                        value: 7,
                        total: 10,
                    },
                    {
                        name: '选择题',
                        value: 17,
                        total: 20,
                    },
                    {
                        name: '应用题',
                        value: 4,
                        total: 5,
                    },
                    // {
                    //     name:'计算题',
                    //     value:2,
                    //     total:3,
                    // },
                    // {
                    //     name:'简答题',
                    //     value:3,
                    //     total:4,
                    // },
                ]

                // this.analysisData = resData;


                //错因分析相关
                let errData = [
                    {
                        title: '学习习惯',
                        data: [
                            {
                                name: '书写不规范',
                                value: 3
                            },
                            {
                                name: '马虎涂改',
                                value: 4
                            },
                            {
                                name: '卷面潦草',
                                value: 2
                            },
                            {
                                name: '其他',
                                value: 1
                            },
                        ]
                    },
                    {
                        title: '突出问题',
                        data: [
                            {
                                name: '过程计算错误',
                                value: 3
                            },
                            {
                                name: '计算结果错误',
                                value: 4
                            },
                            {
                                name: '没作答',
                                value: 2
                            },

                        ]
                    },
                ]
                this.wrongReasonData = errData;
                // this.reasonDraw();

            },
            //当题型分析类型数量少于3的时候渲染柱状图
            analysisBarDraw() {
                let XData = [], YData = [];
                this.question_type_analysis.forEach(item => {
                    XData.push(item.name);
                    YData.push(item.total_score_rate);
                })
                this.radarOption = {
                    xAxis: {
                        type: 'category',
                        data: XData
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [{
                        data: YData,
                        type: 'bar',
                        barWidth: '40%',
                        tooltip: {
                            show: true,
                        },
                        itemStyle: {
                            borderColor: 'rgba(1, 159, 232, 1)',
                            borderWidth: 1,
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1,
                                [{
                                    offset: 0,
                                    color: 'rgba(1, 159, 232, 1)'
                                },
                                    {
                                        offset: 1,
                                        color: 'rgba(106, 208, 255, 1)'
                                    }
                                ],
                                false
                            ),
                        },
                    }]
                };
                radar.setOption(this.radarOption, true);
            },
            //绘制雷达图
            radarDraw() {


                let indicator = [], radarData = [];
                this.question_type_analysis.forEach(item => {
                    indicator.push({
                        name: item.name,
                        max: 100
                    })
                    radarData.push(item.total_score_rate);
                })

                let option = {
                    backgroundColor: '#fff',
                    color: ["rgba(0,183,238, 1)", "rgba(86,199,60, 1)"],
                    tooltip: {
                        show: true,
                        trigger: "item",
                        extraCssText: 'background: #fff; border-radius: 0;box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);color: #333;',
                    },
                    radar: {
                        center: ["50%", "50%"],
                        radius: "70%",
                        startAngle: 90,
                        splitNumber: 4,
                        // shape: "circle",
                        splitArea: {
                            areaStyle: {
                                color: ["transparent"]
                            }
                        },
                        axisLabel: {
                            show: false,
                            fontSize: 20,
                            color: "#000",
                            fontStyle: "normal",
                            fontWeight: "normal"
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#e6e6e6"
                            }
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: "#e6e6e6"
                            }
                        },
                        name: {
                            formatter: '{value}',
                            textStyle: {
                                color: '#333',
                                fontWeight: 'bold'
                            },
                        },
                        indicator: indicator
                    },

                    series: [{
                        name: "题型分析",
                        type: "radar",
                        symbol: "circle",
                        symbolSize: 9,
                        areaStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1,
                                    [{
                                        offset: 0,
                                        color: 'rgba(1, 159, 232, 1)'
                                    },
                                        {
                                            offset: 1,
                                            color: 'rgba(106, 208, 255, 1)'
                                        }
                                    ],
                                    false
                                ),
                            }
                        },
                        itemStyle: {
                            color: 'rgba(1, 159, 232, 1)',
                            borderColor: '#fff',
                            borderWidth: 2,
                        },
                        lineStyle: {
                            normal: {
                                color: "rgba(1, 159, 232, 1)",
                                width: 2
                            }
                        },
                        data: [radarData]
                    }]


                };
                this.radarOption = option;
                radar.setOption(this.radarOption, true);
            },
            //绘制错因分析柱状图
            reasonDraw() {
                //初始化echart
                this.$nextTick(() => {
                    let charts = []
                    for (let i = 0, wLen = this.error_cause_analysis.length; i < wLen; i++) {
                        let target = this.error_cause_analysis[i];
                        charts[i] = this.$echarts.init(this.$refs[`reason_${i}`][0]);

                        this.$nextTick(() => {
                            let XData = [], YData = [], title = target.name;
                            target.tag_list.forEach(item => {
                                XData.push(item.name);
                                YData.push(item.question_count);
                            })
                            let option = {
                                title: {
                                    text:title,
                                    textStyle:{
                                        fontSize: 14,
                                        fontWeight: 'normal'
                                    },
                                },
                                xAxis: {
                                    type: 'category',
                                    data: XData,
                                    axisLabel:{
                                        rotate:45
                                    }
                                },
                                yAxis: {
                                    type: 'value'
                                },
                                series: [{
                                    data: YData,
                                    type: 'bar',
                                    barWidth: '40%',
                                    tooltip: {
                                        show: true,
                                    },
                                    itemStyle: {
                                        borderColor: 'rgba(1, 159, 232, 1)',
                                        borderWidth: 1,
                                        color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1,
                                            [{
                                                offset: 0,
                                                color: 'rgba(1, 159, 232, 1)'
                                            },
                                                {
                                                    offset: 1,
                                                    color: 'rgba(106, 208, 255, 1)'
                                                }
                                            ],
                                            false
                                        ),
                                    },
                                }]
                            };
                            charts[i].setOption(option);
                        })
                    }
                })

            },
            chartInit() {
                radar = this.$echarts.init(this.$refs.radar);
                this.$nextTick(()=>{
                    //
                    // this.radarDraw();
                })
            },
            kpSliderColorInName(scoreRate,type){
                let color = '', text = '';
                if (scoreRate >= 95) {
                    color = '#36BB66'
                    text = '优秀'
                } else if (scoreRate >= 86) {
                    color = '#F9B723';
                    text = '中等';
                } else if (scoreRate >= 70) {
                    color = '#EE7F26';
                    text = '一般';
                } else{
                    color = '#FF0000';
                    text = '待提高';
                }
                return type === 'color' ? color : text;
            }
        },
        mounted() {
            // this.$echarts.init(this.$refs.radar)
        },
        watch:{
            $route(to,from){
                this.postParams.testId = to.query.testId
                if (this.postParams.testId ){
                    this.getAnalysis()
                }
            }
        },
    }
</script>
<style lang="scss">
    .analysisPage {
        @extend .abs_full_screen;
        overflow: hidden;

        &:before {
            content: '';
            position: absolute;
            left: 50%;
            top: -50px;
            height: 250px;
            width: 120%;
            transform: translateX(-50%);
            background: linear-gradient(0deg, #019FE8 0%, #6AD0FF 100%);
            border-radius: 50%;

        }

        .analysis_page_content {
            @extend .abs_full_screen;
            @extend .flex_column;

            .record_bar{
                @extend .flex_row_center;
                position: absolute;
                width: 100px;
                height: 36px;
                right: 0;
                top: 75px;
                background: linear-gradient(
                                -21deg, #47e297 100%, #01c265 0%);;
                box-shadow: 2px 2px 10px 0 rgba(0,0,0,0.3);
                border:{
                    top-left-radius: 10px;
                    bottom-left-radius: 10px;
                }

                .record_text{
                    color: #fff;
                }
            }

            .analysis_title {
                font-size: 16px;
                font-weight: bold;
                text-align: left;
                padding: 12px 16px;
                color: #fff;
            }

            .score_item {
                @extend .flex_column_center;

                .score_number {
                    @extend .flex_row_center;
                    align-items: flex-end;

                    .user_score {
                        font-size: 18px;
                        color: rgba(255, 101, 92, 1);
                        font-weight: bold;
                    }

                    .total_score {
                        font-size: 12px;
                        color: #333;
                    }
                }

                .score_text {
                    color: rgba(102, 102, 102, 1);
                    font-size: 12px;
                }
            }

            .analysis_header {
                padding: 12px;
                background: #fff;
                border-radius: 5px;
                margin: 0 16px;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);

                .header_title {
                    font-size: 16px;
                    font-weight: bold;
                    color: #333;
                    text-align: left;
                }

                .score_process {
                    @extend .flex_row_center;
                    padding-bottom: 12px;

                    .process_box {
                        flex: 1;
                        position: relative;
                        height: 5px;
                        margin: 0 12px;
                        border-radius: 5px;

                        .process_bg {
                            height: 100%;
                            width: 100%;
                            background: #eee;
                            border-radius: 5px;
                        }

                        .process_line {
                            background: linear-gradient(94deg, #009CFF 0%, #23C3FF 52%, #3DDDFF 100%);
                            position: absolute;
                            left: 0;
                            top: 0;
                            height: 100%;
                            width: 80%;
                            border-radius: 5px;
                            transition: all .3s ease;

                            &:after {
                                content: '';
                                position: absolute;
                                background: url("../../public/iconImg/icon_logo.png") no-repeat center;
                                background-size: 100% 100%;
                                width: 17px;
                                height: 17px;
                                left: 100%;
                                top: -17px;
                                transform: translateX(-50%);
                            }
                        }
                    }

                }

                .score_info {
                    @extend .flex_row_center;

                    .score_item {
                        flex: 1;

                        /*&:first-child {*/
                        /*    align-items: flex-start;*/
                        /*}*/

                        /*&:last-child {*/
                        /*    align-items: flex-end;*/
                        /*}*/
                    }
                }
            }

            .analysis_box {
                @extend .flex_height_auto;
                padding: 0 16px;
                margin: 12px 0;
                overflow: auto;

                .analysis_item {
                    padding: 12px;
                    margin-bottom: 12px;
                    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
                    border-radius: 5px;

                    .analysis_block {
                        &:not(:last-child) {
                            margin-bottom: 12px;
                        }

                        .mark_remark {
                            font-weight: normal;
                            color: rgba(255, 101, 92, 1);
                        }

                        .item_title {
                            font-size: 16px;
                            font-weight: bold;
                            color: rgba(1, 159, 232, 1);
                            text-align: left;
                            padding-bottom: 8px;
                        }

                        .item_content {
                            text-align: left;

                            .weakness_item {
                                font-size: 12px;
                                color: rgba(51, 51, 51, 1);
                                font-weight: bold;
                                margin-bottom: 4px;

                                &.weight {
                                    font-weight: bold;
                                }

                            }

                            .canvas_item {
                                height: 315px;
                                width: 100%;
                                /*background: #999;*/
                            }

                            .analysis_line {
                                @extend .flex_row_center;

                                .line_item {
                                    flex: 2;

                                    .line_label {
                                        color: #999;
                                    }

                                    &:not(:last-child) {
                                        margin-right: 12px;
                                    }

                                    &:last-child {
                                        flex: 1;
                                        text-align: right;
                                    }
                                }

                                .rateText {
                                    font-weight: bold;
                                    font-size: 16px;
                                }
                            }
                        }
                    }


                }

                .questionTypeTable{
                    width: 100%;
                    border: 1px solid #e7e7e7 ;
                    tr th{
                        text-align: center;
                        padding: 5px;
                    }
                    .tableContent th{
                        border-top: 1px solid #e7e7e7 !important;
                    }
                }
            }

            .kpAnalysisTitle {
                text-align: center;
                padding: 5px;
                border-bottom: 1px dashed rgb(61 221 255 / 60%);
                span{
                    font-size: 18px;
                    font-weight: bold;
                    color: rgb(61 221 255 / 80%);
                }
            }
            .kpAnalysis{
                color: rgba(51, 51, 51, 100);
                font-size: 13px;
                text-align: center;
                font-family: PingFangSC-regular;
                padding: 5px;
                border-bottom: 1px dashed rgba(187, 187, 187, 45);
                height: 40px;

                .kpSlider{
                    padding: 15px 10px;
                    .van-slider--disabled{
                        opacity: 1;
                    }
                }
                .kpName {
                    font-size: 15px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 40px;
                    //line-height: 35px;
                }
            }
        }
    }
</style>